import React, { useEffect } from 'react';
import { useDiagnosticStore } from './store/diagnosticStore';
import { ProgressBar } from './components/ProgressBar';
import { Question } from './components/Question';
import { Results } from './components/Results';
import { ArrowLeft, ArrowRight } from 'lucide-react';

function App() {
  const { 
    currentSection,
    sections,
    nextSection,
    previousSection,
    answers
  } = useDiagnosticStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!sections || sections.length === 0) {
    return null;
  }

  const isLastSection = currentSection === sections.length - 1;
  const currentSectionAnswered = sections[currentSection].questions.every(
    (q) => answers.some((a) => a.questionId === q.id)
  );

  const renderHeader = () => (
    <div className="aid-header">
      <div className="aid-header-content">
        <img 
          src="https://www.demarretonaventure.com/wp-content/uploads/2024/11/damerre-with-space1.jpg"
          alt="Démarre Ton Aventure" 
          className="aid-header-logo"
        />
        <div className="aid-header-text">
          <h1 className="aid-header-title">Diagnostic IA pour votre entreprise</h1>
          <p className="aid-header-subtitle">
            Découvrez notre outil de diagnostic rapide pour évaluer les capacités de votre entreprise à intégrer et utiliser l'intelligence artificielle. En moins de 10 minutes, identifiez vos forces, vos besoins et les opportunités pour optimiser vos processus grâce à l'IA.
          </p>
        </div>
      </div>
    </div>
  );

  if (isLastSection && currentSectionAnswered) {
    return <Results />;
  }

  return (
    <div className="aid-min-h-screen">
      {renderHeader()}
      <ProgressBar />
      
      <div className="aid-content">
        <h2 className="aid-section-title">
          Section {currentSection + 1} : {sections[currentSection].title}
        </h2>
        <p className="aid-section-description">
          {sections[currentSection].description}
        </p>

        {sections[currentSection].questions.map((question) => (
          <Question key={question.id} question={question} />
        ))}
      </div>

      <div className="aid-navigation">
        <button
          onClick={previousSection}
          disabled={currentSection === 0}
          className="aid-button aid-button-previous"
          type="button"
        >
          <ArrowLeft className="aid-w-5 aid-h-5" />
          <span>Précédent</span>
        </button>

        <button
          onClick={nextSection}
          disabled={!currentSectionAnswered}
          className="aid-button aid-button-next"
          type="button"
        >
          <span>{isLastSection ? 'Voir les résultats' : 'Suivant'}</span>
          <ArrowRight className="aid-w-5 aid-h-5" />
        </button>
      </div>
    </div>
  );
}

export default App;