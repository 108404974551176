import React from 'react';
import { useDiagnosticStore } from '../store/diagnosticStore';

export const ProgressBar = () => {
  const { currentSection, sections, answers } = useDiagnosticStore();
  
  const totalQuestions = sections.reduce((acc, section) => acc + section.questions.length, 0);
  const answeredQuestions = Math.min(answers.length, totalQuestions);
  const progress = Math.min(Math.round((answeredQuestions / totalQuestions) * 100), 100);

  return (
    <div className="aid-progress-container">
      <div className="aid-progress-info">
        <span className="aid-progress-count">
          Questions répondues : {answeredQuestions}/{totalQuestions}
        </span>
        <span className="aid-progress-percentage">{progress}%</span>
      </div>
      
      <div className="aid-progress-bar">
        <div 
          className="aid-progress-fill"
          style={{ width: `${progress}%` }}
        />
      </div>

      <div className="aid-progress-steps">
        {sections.map((section, index) => (
          <div 
            key={section.id}
            className={`aid-progress-step ${index <= currentSection ? 'aid-progress-step-active' : ''}`}
          >
            <div className="aid-progress-number">{index + 1}</div>
            <div className="aid-progress-label">{section.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};