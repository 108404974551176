import React from 'react';
import { Question as QuestionType, useDiagnosticStore } from '../store/diagnosticStore';

interface QuestionProps {
  question: QuestionType;
}

export const Question = ({ question }: QuestionProps) => {
  const { answers, setAnswer } = useDiagnosticStore();
  const currentAnswer = answers.find((a) => a.questionId === question.id);

  return (
    <div className="aid-question">
      <h3 className="aid-question-text">
        {question.text}
      </h3>
      <div className="aid-options">
        {question.options.map((option) => {
          const isSelected = currentAnswer?.optionId === option.id;
          return (
            <button
              key={option.id}
              onClick={() => setAnswer(question.id, option.id)}
              className={`aid-option ${isSelected ? 'aid-option-selected' : ''}`}
              type="button"
            >
              <div className={`aid-radio ${isSelected ? 'aid-radio-selected' : ''}`}>
                {isSelected && <div className="aid-radio-dot" />}
              </div>
              <span className="aid-option-text">{option.text}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};