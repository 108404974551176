import { create } from 'zustand';

export type Section = {
  id: string;
  title: string;
  description: string;
  questions: Question[];
};

export type Question = {
  id: string;
  text: string;
  options: Option[];
};

export type Option = {
  id: string;
  text: string;
  points: number;
};

export type Answer = {
  questionId: string;
  optionId: string;
};

type DiagnosticStore = {
  currentSection: number;
  answers: Answer[];
  sections: Section[];
  setAnswer: (questionId: string, optionId: string) => void;
  nextSection: () => void;
  previousSection: () => void;
  calculateScore: () => { [key: string]: number };
};

const scrollToTop = () => {
  window.scrollTo(0, 0);
  requestAnimationFrame(() => {
    document.getElementById('ai-diagnostic-root')?.scrollIntoView();
  });
};

export const useDiagnosticStore = create<DiagnosticStore>((set, get) => ({
  currentSection: 0,
  answers: [],
  sections: [
    {
      id: 'generative-ai',
      title: 'IA Générative',
      description: 'Évaluez votre connaissance et utilisation de l\'IA générative',
      questions: [
        {
          id: 'gen-ai-1',
          text: 'Êtes-vous familier avec les outils d\'IA générative, comme ChatGPT, Claude 3, Gemini, ou d\'autres similaires ?',
          options: [
            { id: 'gen-1-1', text: 'Oui, nous utilisons ces outils régulièrement', points: 3 },
            { id: 'gen-1-2', text: 'Nous comprenons le concept mais ne les utilisons pas encore', points: 2 },
            { id: 'gen-1-3', text: 'Nous ne sommes pas familiers avec ces outils', points: 1 },
          ],
        },
        {
          id: 'gen-ai-2',
          text: 'Avez-vous déjà exploré l\'utilisation de l\'IA générative pour améliorer vos processus métiers ?',
          options: [
            { id: 'gen-2-1', text: 'Oui, nous avons déjà des cas d\'usage spécifiques', points: 3 },
            { id: 'gen-2-2', text: 'Nous y réfléchissons mais n\'avons pas encore commencé', points: 2 },
            { id: 'gen-2-3', text: 'Non, nous n\'avons pas encore exploré cette technologie', points: 1 },
          ],
        },
        {
          id: 'gen-ai-3',
          text: 'Utilisez-vous des outils d\'IA générative pour vous assister dans la rédaction de rapports, d\'emails ou de documents ?',
          options: [
            { id: 'gen-3-1', text: 'Oui, fréquemment', points: 3 },
            { id: 'gen-3-2', text: 'De temps en temps', points: 2 },
            { id: 'gen-3-3', text: 'Non, jamais', points: 1 },
          ],
        },
        {
          id: 'gen-ai-4',
          text: 'Avez-vous développé des automatisations d\'IA générative (comme les GPT\'s) pour des besoins spécifiques de votre entreprise ?',
          options: [
            { id: 'gen-4-1', text: 'Oui, nous avons développé des GPT\'s sur mesure', points: 3 },
            { id: 'gen-4-2', text: 'Non, nous utilisons uniquement ChatGPT tel quel', points: 2 },
            { id: 'gen-4-3', text: 'Je ne sais pas ce que signifie "GPT\'s" et automatisation avec l\'IA générative', points: 1 },
          ],
        },
      ],
    },
    {
      id: 'technical-skills',
      title: 'Compétences Techniques',
      description: 'Évaluez les compétences internes et les besoins en formation',
      questions: [
        {
          id: 'tech-1',
          text: 'Avez-vous des employés ayant des compétences techniques en IA ou en apprentissage automatique ?',
          options: [
            { id: 'tech-1-1', text: 'Oui, nous avons des experts internes', points: 3 },
            { id: 'tech-1-2', text: 'Oui, mais ils ne sont pas spécialisés en IA', points: 2 },
            { id: 'tech-1-3', text: 'Non, nous n\'avons pas ce type de compétences en interne', points: 1 },
          ],
        },
        {
          id: 'tech-2',
          text: 'Avez-vous déjà formé vos équipes à l\'utilisation de l\'IA générative ou d\'autres outils basés sur l\'IA ?',
          options: [
            { id: 'tech-2-1', text: 'Oui, nous avons un programme de formation en place', points: 3 },
            { id: 'tech-2-2', text: 'Nous avons fait quelques formations ponctuelles', points: 2 },
            { id: 'tech-2-3', text: 'Non, nous n\'avons pas encore formé nos équipes', points: 1 },
          ],
        },
        {
          id: 'tech-3',
          text: 'Disposez-vous de profils spécialisés dans la science des données ou en data engineering ?',
          options: [
            { id: 'tech-3-1', text: 'Oui, nous avons une équipe dédiée', points: 3 },
            { id: 'tech-3-2', text: 'Nous avons un ou deux experts dans ces domaines', points: 2 },
            { id: 'tech-3-3', text: 'Non, nous n\'avons pas ce type de profils en interne', points: 1 },
          ],
        },
        {
          id: 'tech-4',
          text: 'Faites-vous appel à des consultants ou des prestataires externes pour des projets liés à l\'IA ?',
          options: [
            { id: 'tech-4-1', text: 'Oui, nous collaborons régulièrement avec des experts externes', points: 3 },
            { id: 'tech-4-2', text: 'De temps en temps, en fonction des besoins spécifiques', points: 2 },
            { id: 'tech-4-3', text: 'Non, nous gérons tout en interne ou nous n\'avons pas encore eu besoin de cela', points: 1 },
          ],
        },
        {
          id: 'tech-5',
          text: 'Êtes-vous en train de recruter ou prévoyez-vous de recruter des profils spécialisés en IA ?',
          options: [
            { id: 'tech-5-1', text: 'Oui, nous avons actuellement un processus de recrutement en cours', points: 3 },
            { id: 'tech-5-2', text: 'Nous envisageons de recruter à moyen terme', points: 2 },
            { id: 'tech-5-3', text: 'Non, nous n\'avons pas de plan de recrutement dans ce domaine', points: 1 },
          ],
        },
      ],
    },
    {
      id: 'infrastructure',
      title: 'Infrastructure Technologique',
      description: 'Évaluez votre infrastructure technique et la gestion des données',
      questions: [
        {
          id: 'infra-1',
          text: 'Votre infrastructure informatique actuelle est-elle adaptée pour supporter des applications d\'IA ?',
          options: [
            { id: 'infra-1-1', text: 'Oui, nous disposons d\'une infrastructure robuste', points: 3 },
            { id: 'infra-1-2', text: 'Oui, mais des améliorations seraient nécessaires', points: 2 },
            { id: 'infra-1-3', text: 'Non, notre infrastructure n\'est pas prête pour l\'IA', points: 1 },
          ],
        },
        {
          id: 'infra-2',
          text: 'Utilisez-vous des services de cloud computing pour le stockage et le traitement de vos données ?',
          options: [
            { id: 'infra-2-1', text: 'Oui, nous utilisons des services comme AWS, Azure, ou Google Cloud', points: 3 },
            { id: 'infra-2-2', text: 'Nous utilisons des solutions de cloud internes ou privées', points: 2 },
            { id: 'infra-2-3', text: 'Non, nous ne travaillons pas sur le cloud', points: 1 },
          ],
        },
        {
          id: 'infra-3',
          text: 'Avez-vous une stratégie claire de collecte, de stockage et de gestion de vos données ?',
          options: [
            { id: 'infra-3-1', text: 'Oui, nous avons des processus définis pour cela', points: 3 },
            { id: 'infra-3-2', text: 'Nous avons une stratégie partielle, mais elle pourrait être améliorée', points: 2 },
            { id: 'infra-3-3', text: 'Non, nous n\'avons pas de stratégie formalisée', points: 1 },
          ],
        },
        {
          id: 'infra-4',
          text: 'La qualité et la quantité de vos données sont-elles suffisantes pour implémenter des solutions d\'IA ?',
          options: [
            { id: 'infra-4-1', text: 'Oui, nous disposons de données fiables et en grande quantité', points: 3 },
            { id: 'infra-4-2', text: 'Nous avons des données, mais elles doivent être nettoyées ou améliorées', points: 2 },
            { id: 'infra-4-3', text: 'Non, nous manquons de données ou la qualité est insuffisante', points: 1 },
          ],
        },
        {
          id: 'infra-5',
          text: 'Avez-vous déjà mis en place des pratiques d\'analyse de données au sein de votre entreprise ?',
          options: [
            { id: 'infra-5-1', text: 'Oui, nous utilisons des outils d\'analyse avancés', points: 3 },
            { id: 'infra-5-2', text: 'Oui, mais uniquement pour des analyses basiques', points: 2 },
            { id: 'infra-5-3', text: 'Non, nous n\'avons pas encore mis cela en place', points: 1 },
          ],
        },
      ],
    },
    {
      id: 'automation',
      title: 'Automatisation des Processus',
      description: 'Évaluez vos besoins en automatisation et votre utilisation actuelle de l\'IA',
      questions: [
        {
          id: 'auto-1',
          text: 'Identifiez-vous des processus métiers répétitifs et chronophages dans votre entreprise ?',
          options: [
            { id: 'auto-1-1', text: 'Oui, nous avons plusieurs processus de ce type', points: 1 },
            { id: 'auto-1-2', text: 'Oui, mais ils ne représentent qu\'une petite partie de nos opérations', points: 2 },
            { id: 'auto-1-3', text: 'Non, la plupart de nos processus sont déjà optimisés ou automatisés', points: 3 },
          ],
        },
        {
          id: 'auto-2',
          text: 'Combien de temps par semaine consacrez-vous à des tâches répétitives manuelles ?',
          options: [
            { id: 'auto-2-1', text: 'Plus de 20 heures', points: 1 },
            { id: 'auto-2-2', text: 'Entre 10 et 20 heures', points: 2 },
            { id: 'auto-2-3', text: 'Moins de 10 heures', points: 3 },
          ],
        },
        {
          id: 'auto-3',
          text: 'Avez-vous des outils ou mis en place des solutions d\'IA pour automatiser certaines tâches ?',
          options: [
            { id: 'auto-3-1', text: 'Oui, nous utilisons déjà des outils d\'IA pour automatiser', points: 3 },
            { id: 'auto-3-2', text: 'Non, mais nous envisageons d\'adopter ces outils', points: 2 },
            { id: 'auto-3-3', text: 'Non, nous ne nous sommes pas encore penchés sur ces technologies', points: 1 },
          ],
        },
        {
          id: 'auto-4',
          text: 'Avez-vous une idée du retour sur investissement potentiel de l\'automatisation par l\'IA ?',
          options: [
            { id: 'auto-4-1', text: 'Oui, nous avons déjà évalué l\'impact', points: 3 },
            { id: 'auto-4-2', text: 'Non, mais nous aimerions obtenir une évaluation', points: 2 },
            { id: 'auto-4-3', text: 'Non, nous n\'avons pas encore exploré cette dimension', points: 1 },
          ],
        },
      ],
    },
    {
      id: 'innovation-culture',
      title: 'Culture d\'Innovation',
      description: 'Évaluez votre ouverture à l\'innovation et votre gestion du changement',
      questions: [
        {
          id: 'innov-1',
          text: 'Quelle est l\'attitude générale de la direction et des employés vis-à-vis des nouvelles technologies ?',
          options: [
            { id: 'innov-1-1', text: 'Nous sommes très ouverts aux nouvelles technologies', points: 3 },
            { id: 'innov-1-2', text: 'Nous adoptons les technologies de manière modérée', points: 2 },
            { id: 'innov-1-3', text: 'Nous sommes plutôt prudents', points: 1 },
          ],
        },
        {
          id: 'innov-2',
          text: 'Avez-vous déjà adopté des solutions innovantes dans le passé ?',
          options: [
            { id: 'innov-2-1', text: 'Oui, nous adoptons régulièrement de nouvelles technologies', points: 3 },
            { id: 'innov-2-2', text: 'Oui, mais nous procédons avec précaution', points: 2 },
            { id: 'innov-2-3', text: 'Non, nous avons peu d\'expérience avec l\'adoption de nouvelles technologies', points: 1 },
          ],
        },
        {
          id: 'innov-3',
          text: 'Avez-vous déjà mis en place des stratégies de gestion du changement ?',
          options: [
            { id: 'innov-3-1', text: 'Oui, nous avons une méthodologie éprouvée', points: 3 },
            { id: 'innov-3-2', text: 'Nous avons parfois accompagné les changements, sans stratégie formalisée', points: 2 },
            { id: 'innov-3-3', text: 'Non, nous n\'avons pas encore mis en place de gestion du changement', points: 1 },
          ],
        },
        {
          id: 'innov-4',
          text: 'Quel type de formation offrez-vous lors de l\'adoption de nouvelles technologies ?',
          options: [
            { id: 'innov-4-1', text: 'Nous offrons des formations complètes et un accompagnement long terme', points: 3 },
            { id: 'innov-4-2', text: 'Nous offrons des formations de base au démarrage', points: 2 },
            { id: 'innov-4-3', text: 'Nous laissons les employés apprendre par eux-mêmes', points: 1 },
          ],
        },
      ],
    },
    {
      id: 'strategy',
      title: 'Stratégie d\'Entreprise',
      description: 'Évaluez l\'intégration de l\'IA dans votre stratégie d\'entreprise',
      questions: [
        {
          id: 'strat-1',
          text: 'L\'IA fait-elle partie de votre stratégie d\'entreprise à moyen ou long terme ?',
          options: [
            { id: 'strat-1-1', text: 'Oui, elle est intégrée dans notre stratégie globale', points: 3 },
            { id: 'strat-1-2', text: 'Oui, mais de manière expérimentale ou ponctuelle', points: 2 },
            { id: 'strat-1-3', text: 'Non, elle n\'est pas encore intégrée', points: 1 },
          ],
        },
        {
          id: 'strat-2',
          text: 'Avez-vous défini des objectifs spécifiques à atteindre grâce à l\'IA ?',
          options: [
            { id: 'strat-2-1', text: 'Oui, nous avons des objectifs clairs et mesurables', points: 3 },
            { id: 'strat-2-2', text: 'Oui, mais ces objectifs sont encore flous', points: 2 },
            { id: 'strat-2-3', text: 'Non, nous n\'avons pas encore défini d\'objectifs', points: 1 },
          ],
        },
        {
          id: 'strat-3',
          text: 'Avez-vous déjà alloué un budget spécifique pour des projets liés à l\'IA ?',
          options: [
            { id: 'strat-3-1', text: 'Oui, nous avons un budget dédié à l\'IA', points: 3 },
            { id: 'strat-3-2', text: 'Nous avons un budget limité pour l\'IA', points: 2 },
            { id: 'strat-3-3', text: 'Non, nous n\'avons pas encore alloué de budget', points: 1 },
          ],
        },
        {
          id: 'strat-4',
          text: 'Êtes-vous prêts à investir dans de nouveaux outils technologiques ou à recruter des talents pour l\'IA ?',
          options: [
            { id: 'strat-4-1', text: 'Oui, nous sommes prêts à investir dans des outils et talents', points: 3 },
            { id: 'strat-4-2', text: 'Nous pourrions envisager des investissements si nécessaire', points: 2 },
            { id: 'strat-4-3', text: 'Non, nous préférons optimiser les ressources actuelles', points: 1 },
          ],
        },
      ],
    },
    {
      id: 'custom-solutions',
      title: 'Solutions IA sur Mesure',
      description: 'Évaluez vos besoins en solutions IA personnalisées',
      questions: [
        {
          id: 'custom-1',
          text: 'Avez-vous des défis métiers spécifiques que vous aimeriez résoudre grâce à l\'IA ?',
          options: [
            { id: 'custom-1-1', text: 'Oui, nous avons identifié plusieurs besoins précis', points: 3 },
            { id: 'custom-1-2', text: 'Nous avons quelques idées mais pas encore bien définies', points: 2 },
            { id: 'custom-1-3', text: 'Non, nous n\'avons pas encore identifié de cas d\'usage', points: 1 },
          ],
        },
        {
          id: 'custom-2',
          text: 'Pensez-vous que des solutions IA sur mesure seraient plus adaptées à vos besoins ?',
          options: [
            { id: 'custom-2-1', text: 'Oui, nous avons besoin de solutions adaptées à nos processus', points: 3 },
            { id: 'custom-2-2', text: 'Les solutions standard pourraient suffire pour certains besoins', points: 2 },
            { id: 'custom-2-3', text: 'Nous ne savons pas encore quel type de solution conviendrait', points: 1 },
          ],
        },
        {
          id: 'custom-3',
          text: 'Disposez-vous des ressources internes pour développer et maintenir des solutions IA sur mesure ?',
          options: [
            { id: 'custom-3-1', text: 'Oui, nous avons une équipe capable de développer ces solutions', points: 3 },
            { id: 'custom-3-2', text: 'Non, mais nous envisageons de recruter ou collaborer', points: 2 },
            { id: 'custom-3-3', text: 'Non, nous préférons externaliser ce type de projet', points: 1 },
          ],
        },
        {
          id: 'custom-4',
          text: 'Avez-vous déjà travaillé avec des partenaires pour développer des solutions sur mesure ?',
          options: [
            { id: 'custom-4-1', text: 'Oui, nous avons déjà eu des collaborations fructueuses', points: 3 },
            { id: 'custom-4-2', text: 'Nous avons fait quelques expériences sans résultats concrets', points: 2 },
            { id: 'custom-4-3', text: 'Non, nous n\'avons pas encore collaboré pour des solutions sur mesure', points: 1 },
          ],
        },
      ],
    },
  ],

  setAnswer: (questionId, optionId) =>
    set((state) => ({
      answers: [
        ...state.answers.filter((a) => a.questionId !== questionId),
        { questionId, optionId },
      ],
    })),

  nextSection: () => {
    set((state) => ({
      currentSection: Math.min(state.currentSection + 1, state.sections.length - 1),
    }));
    scrollToTop();
  },

  previousSection: () => {
    set((state) => ({
      currentSection: Math.max(state.currentSection - 1, 0),
    }));
    scrollToTop();
  },

  calculateScore: () => {
    const state = get();
    const scores: { [key: string]: number } = {};

    state.sections.forEach((section) => {
      let sectionScore = 0;
      let answeredQuestions = 0;

      section.questions.forEach((question) => {
        const answer = state.answers.find((a) => a.questionId === question.id);
        if (answer) {
          const option = question.options.find((o) => o.id === answer.optionId);
          if (option) {
            sectionScore += option.points;
            answeredQuestions++;
          }
        }
      });

      scores[section.id] = answeredQuestions > 0
        ? Math.round((sectionScore / (answeredQuestions * 3)) * 100)
        : 0;
    });

    return scores;
  },
}));