import React, { useEffect } from 'react';
import { RadarChart, PolarGrid, PolarAngleAxis, Radar, ResponsiveContainer } from 'recharts';
import { useDiagnosticStore } from '../store/diagnosticStore';

export const Results = () => {
  const { sections, calculateScore, answers } = useDiagnosticStore();
  const scores = calculateScore();

  const data = sections.map((section) => ({
    subject: section.title.toUpperCase(),
    value: scores[section.id] || 0,
  }));

  const averageScore = Math.round(
    Object.values(scores).reduce((a, b) => a + b, 0) / sections.length
  );

  const getMaturityLevel = (score: number) => {
    if (score >= 80) return 'Expert';
    if (score >= 60) return 'Avancé';
    if (score >= 40) return 'Intermédiaire';
    return 'Débutant';
  };

  const getDetailedRecommendation = (section: string, score: number) => {
    const recommendations = {
      'IA Générative': {
        low: `Pour améliorer votre utilisation de l'IA générative, nous vous recommandons de commencer par une phase d'exploration et de formation. Familiarisez vos équipes avec des outils comme ChatGPT, Claude ou Gemini à travers des ateliers pratiques. Identifiez 2-3 cas d'usage simples dans votre entreprise (par exemple : assistance à la rédaction, analyse de données, support client) et lancez des projets pilotes. Documentez les résultats et les apprentissages pour faciliter l'adoption progressive. Envisagez de nommer un "champion IA" dans chaque département pour accélérer l'appropriation des outils.`,
        medium: `Vous avez déjà une base solide en IA générative, mais il reste des opportunités d'amélioration. Concentrez-vous sur l'optimisation de vos processus actuels en intégrant des prompts plus sophistiqués et en développant des workflows automatisés. Formez vos équipes aux bonnes pratiques d'utilisation et à la création de prompts efficaces. Explorez la création de GPTs personnalisés pour vos besoins spécifiques et envisagez l'intégration d'API d'IA générative dans vos outils existants.`
      },
      'Compétences Techniques': {
        low: `Le développement des compétences techniques est crucial pour votre transformation numérique. Nous recommandons de mettre en place un programme de formation structuré sur 6-12 mois, couvrant les fondamentaux de l'IA jusqu'aux applications pratiques. Identifiez les collaborateurs ayant un potentiel d'évolution vers des rôles techniques et investissez dans leur formation. Parallèlement, établissez des partenariats avec des experts externes pour un transfert de compétences et envisagez le recrutement stratégique de profils techniques. Créez une communauté de pratique interne pour favoriser le partage de connaissances.`,
        medium: `Pour renforcer vos compétences techniques existantes, développez un plan de montée en compétences avancées. Organisez des sessions de formation spécialisées sur des sujets comme le deep learning, le NLP, ou l'automatisation avancée. Encouragez la certification de vos équipes sur des plateformes reconnues. Créez des groupes de travail mixtes associant experts techniques et métiers pour maximiser le transfert de connaissances.`
      },
      'Infrastructure Technologique': {
        low: `Votre infrastructure nécessite une modernisation significative pour supporter les projets d'IA. Commencez par un audit complet de votre infrastructure actuelle. Élaborez un plan de migration vers le cloud en privilégiant une approche hybride si nécessaire. Mettez en place une architecture de données robuste incluant des data lakes et des pipelines de données automatisés. Investissez dans des outils de monitoring et de sécurité adaptés aux projets IA. Prévoyez des environnements de test et de production séparés pour faciliter l'expérimentation.`,
        medium: `Pour optimiser votre infrastructure existante, concentrez-vous sur l'amélioration de la scalabilité et de la performance. Évaluez l'adoption de solutions conteneurisées et de microservices pour plus de flexibilité. Renforcez votre gouvernance des données et mettez en place des processus de qualité des données plus rigoureux. Optimisez vos coûts cloud et investissez dans des outils d'automatisation de l'infrastructure.`
      },
      'Automatisation des Processus': {
        low: `L'automatisation représente une opportunité majeure d'amélioration de votre efficacité opérationnelle. Commencez par cartographier tous vos processus métiers et identifiez ceux qui sont les plus chronophages ou sujets aux erreurs. Priorisez 3-5 processus à fort impact pour une automatisation rapide. Utilisez des outils low-code/no-code pour des gains rapides tout en développant une stratégie d'automatisation plus avancée. Formez vos équipes aux méthodologies d'automatisation et à l'utilisation des outils RPA (Robotic Process Automation).`,
        medium: `Pour améliorer votre niveau d'automatisation, passez à une approche plus sophistiquée intégrant l'IA. Développez des automatisations intelligentes capables de traiter des cas complexes et d'apprendre de leurs erreurs. Mettez en place des indicateurs de performance pour mesurer l'impact de l'automatisation. Créez un centre d'excellence en automatisation pour standardiser les pratiques et maximiser la réutilisation des composants.`
      },
      'Culture d\'Innovation': {
        low: `La transformation de votre culture d'entreprise est essentielle pour réussir avec l'IA. Mettez en place un programme d'innovation structuré incluant des sessions de sensibilisation régulières, des hackathons, et des challenges d'innovation. Créez un espace dédié à l'expérimentation où les équipes peuvent tester de nouvelles idées sans crainte de l'échec. Instaurez un système de reconnaissance et de récompense pour les initiatives innovantes. Organisez des visites d'entreprises innovantes et des partages d'expérience.`,
        medium: `Pour renforcer votre culture d'innovation, développez des programmes plus ambitieux. Créez un lab d'innovation interne avec un budget dédié. Établissez des partenariats avec des startups et des centres de recherche. Mettez en place un processus d'innovation ouverte impliquant clients et partenaires. Développez un programme d'intrapreneuriat pour encourager l'émergence de nouveaux projets.`
      },
      'Stratégie d\'Entreprise': {
        low: `L'intégration de l'IA dans votre stratégie nécessite une approche structurée. Commencez par définir une vision claire de la place de l'IA dans votre entreprise à 3-5 ans. Identifiez les domaines prioritaires où l'IA peut créer le plus de valeur. Établissez une feuille de route détaillée avec des objectifs mesurables et des jalons clairs. Allouez un budget spécifique aux initiatives IA et définissez une gouvernance adaptée. Impliquez toutes les parties prenantes dans la définition et le déploiement de la stratégie.`,
        medium: `Pour renforcer votre stratégie IA, adoptez une approche plus ambitieuse. Développez des cas d'usage innovants qui peuvent créer de nouveaux avantages concurrentiels. Établissez des partenariats stratégiques pour accélérer votre développement. Mettez en place un comité d'innovation IA au niveau de la direction. Développez des indicateurs de performance spécifiques à l'IA et suivez leur évolution régulièrement.`
      },
      'Solutions IA sur Mesure': {
        low: `Le développement de solutions IA personnalisées nécessite une approche méthodique. Commencez par une analyse approfondie de vos besoins spécifiques et des solutions existantes sur le marché. Identifiez les cas d'usage où des solutions sur mesure apporteraient le plus de valeur. Constituez une équipe pluridisciplinaire associant experts métiers et techniques. Adoptez une approche agile avec des cycles courts de développement et de validation. Investissez dans des outils de développement et de déploiement adaptés.`,
        medium: `Pour améliorer vos capacités de développement de solutions IA, structurez votre approche. Créez une plateforme de développement IA standardisée. Mettez en place des processus de MLOps pour industrialiser le développement et le déploiement. Développez une bibliothèque de composants réutilisables. Renforcez vos pratiques de test et de validation des modèles IA.`
      }
    };

    return score < 40 ? recommendations[section]?.low : recommendations[section]?.medium;
  };

  useEffect(() => {
    const sendResultsByEmail = async () => {
      try {
        const maturityLevel = getMaturityLevel(averageScore);
        
        const detailedAnswers = answers.map(answer => {
          const section = sections.find(s => 
            s.questions.some(q => q.id === answer.questionId)
          );
          const question = section?.questions.find(q => q.id === answer.questionId);
          const selectedOption = question?.options.find(o => o.id === answer.optionId);

          return {
            section: section?.title || '',
            question: question?.text || '',
            answer: selectedOption?.text || ''
          };
        });

        const response = await fetch((window as any).aiDiagnosticData.apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-WP-Nonce': (window as any).aiDiagnosticData.nonce
          },
          body: JSON.stringify({
            scores,
            averageScore,
            maturityLevel,
            answers: detailedAnswers
          })
        });

        const result = await response.json();
        if (!result.success) {
          console.error('Erreur lors de l\'envoi des résultats:', result.message);
        }
      } catch (error) {
        console.error('Erreur lors de l\'envoi des résultats:', error);
      }
    };

    sendResultsByEmail();
  }, [scores, averageScore, answers, sections]);

  return (
    <div className="aid-min-h-screen">
      <div className="aid-header">
        <div className="aid-header-content">
          <img 
            src="https://www.demarretonaventure.com/wp-content/uploads/2024/11/damerre-with-space1.jpg"
            alt="Démarre Ton Aventure" 
            className="aid-header-logo"
          />
          <div className="aid-header-text">
            <h1 className="aid-header-title">Diagnostic IA pour votre entreprise</h1>
            <p className="aid-header-subtitle">
              Découvrez notre outil de diagnostic rapide pour évaluer les capacités de votre entreprise à intégrer et utiliser l'intelligence artificielle.
            </p>
          </div>
        </div>
      </div>

      <div className="aid-results-container">
        <div className="aid-results-header">
          <h2 className="aid-results-title">Résultats de votre diagnostic</h2>
          <div className="aid-results-summary">
            <p className="aid-maturity-level">
              Niveau de maturité : <span>{getMaturityLevel(averageScore)}</span>
            </p>
            <p className="aid-total-score">
              Score global : <span>{averageScore}%</span>
            </p>
          </div>
        </div>

        <div className="aid-radar-section">
          <h3 className="aid-radar-title">Niveau de maturité par domaine</h3>
          <div className="aid-radar-chart" style={{ width: '100%', height: 500 }}>
            <ResponsiveContainer>
              <RadarChart data={data}>
                <PolarGrid gridType="circle" />
                <PolarAngleAxis
                  dataKey="subject"
                  tick={{ fill: '#1a1a1a', fontSize: 14, fontFamily: 'League Spartan' }}
                />
                <Radar
                  name="Score"
                  dataKey="value"
                  stroke="#E88F23"
                  fill="#E88F23"
                  fillOpacity={0.2}
                />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="aid-recommendations-section">
          <h3 className="aid-recommendations-title">Recommandations Personnalisées</h3>
          <div className="aid-recommendations-list">
            {sections.map((section) => {
              const score = scores[section.id];
              if (score >= 60) return null;

              return (
                <div key={section.id} className="aid-recommendation-item">
                  <h4 className="aid-recommendation-title">
                    Amélioration pour {section.title}
                  </h4>
                  <p className="aid-recommendation-content">
                    {getDetailedRecommendation(section.title, score)}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="aid-cta-section">
          <a 
            href="https://www.demarretonaventure.com/audit-ia-gratuit-entreprise/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="aid-cta-button"
          >
            Voir pour un audit IA gratuit et sans engagement
          </a>
        </div>
      </div>
    </div>
  );
};